<template>
  <a-card :bordered="false" class="card-area">
    <a-radio-group v-model="mode" :style="{ marginBottom: '8px' }" button-style="solid">
      <a-radio-button value="order" style="width:200px;text-align: center">
        <a-icon type="snippets"/>
        订单管理
      </a-radio-button>
      <a-radio-button value="token" style="width:200px;text-align: center">
        <a-icon type="ordered-list"/>
        Token管理
      </a-radio-button>
    </a-radio-group>
    <component :is="componentName"></component>
  </a-card>
</template>

<script>
import CleanOrderList from "./CleanOrderList";
import CleanTokenList from "./CleanTokenList";

export default {
  name: "CleanManage",
  components: {CleanOrderList, CleanTokenList},
  data() {
    return {
      mode: 'order',
    }
  },
  computed: {
    componentName() {
      return this.mode === 'order' ? 'CleanOrderList' : 'CleanTokenList'
    }
  }
}
</script>

<style scoped>

</style>