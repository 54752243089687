<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.type"
              placeholder="类型"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.cleanTokenType" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>

        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.status"
              placeholder="类型"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.cleanTokenStatus" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.orderNo"
              placeholder="订单号"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button type="danger" @click="importToken">批量导入Token</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="goodName" slot-scope="text, record">
        <div style="display: flex;align-items: center">
          <el-image
              v-if="record.goodsInfo"
              style="min-width: 80px; min-height: 60px;max-width: 80px; max-height: 60px"
              :src="record.goodsInfo.goods_pic | fullKbsImgPath"
              fit="contain"
              :preview-src-list="[fullKbsImgPath(record.goodsInfo.goods_pic)]"
          />
          <div style="flex:1;font-size:0.9em;margin-left:5px">
            {{ record.goodName }}
          </div>
        </div>
      </template>

      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('cleanTokenStatus', 'color')" style="cursor: pointer">
          {{ record.status| dictName("cleanTokenStatus") }}
        </a-tag>
      </template>
      <template slot="type" slot-scope="text, record">
        <a-tag :color="record.type | dictName('cleanTokenType', 'color')" style="cursor: pointer">
          {{ record.type| dictName("cleanTokenType") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button v-if="record.sendStatus==1" type="primary" icon="reload" size="small" @click="syncStatus(record)">
          修改
        </a-button>
        <a-button v-else type="danger" icon="delete" size="small" @click="deleteOne(record)" style="margin-left:10px">
          删除
        </a-button>
      </template>
    </a-table>
    <import-token-modal ref="importModal" @success="search(1)"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import ImportTokenModal from "./importTokenModal";


export default {
  name: "CleanTokenList",
  components: {ImportTokenModal},
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "cleanSale/tokenList",
      listMethod: "get",
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "Token",
          align: "center",
          width: 150,
          dataIndex: "token",
        },
        {
          title: "类型",
          align: "center",
          width: 120,
          scopedSlots: {customRender: "type"},
        },
        {
          title: "状态",
          align: "center",
          width: 100,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },

        {
          title: "导入时间",
          width: 160,
          dataIndex: "createTime",
          align: "center",
        },
        {
          title: "订单编号",
          align: "center",
          width: 160,
          dataIndex: "orderNo",
        },
        {
          title: "下单日期",
          align: "center",
          width: 160,
          dataIndex: "orderTime",
        },
        {
          title: "备注",
          width: 200,
          align: "center",
          dataIndex: "remark",
        },
        {
          title: "操作",
          width: 180,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    importToken() {
      this.$refs.importModal.showModal();
    },

    deleteOne(record) {
      let that = this
      if (record.sendStatus == 1) {
        that.$message.warn('已发送到第三方，不能删除')
        return
      }
      this.$confirm({
        title: '删除确认',
        content: '您确定要删除订单【' + record.id + '】吗？！',
        centered: true,
        onOk() {
          that.$post('cardSale/deleteOrder', {id: record.id}).then(() => {
            that.$message.success('删除成功')
            that.search()
          })
        },
        onCancel() {
          that.selectedRowKeys = []
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
